// 1.関数の定義
function setHeight() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

// 2.初期化
setHeight();

// 3.ブラウザのサイズが変更された時・画面の向きを変えた時に再計算する
window.addEventListener("resize", setHeight);

jQuery(function() {
  jQuery(".faq-q.faq-item-txtbox").each(function() {
    jQuery(this).on("click", function() {
      jQuery(this).toggleClass("on");
      jQuery(this)
        .next(".faq-a.faq-item-txtbox")
        .slideToggle();
      return false;
    });
  });
});
